link {
  font-weight: bold;
  text-decoration: underline;
  color: darkblue;
}

.subNavLink {
  color: white;
}

.subNavLinkActive {
  color: white;
  font-weight: bold;
  text-decoration: underline;
}

.cell-danger {
  font-weight: bold;
  background-color: darkred;
  color: white;
  padding-top: 8px;
  width: 100%;
  height: 100%;
  text-align: center;
}

.cell-warning {
  font-weight: bold;
  background-color: rgb(196, 176, 0);
  color: white;
  padding-top: 8px;
  width: 100%;
  height: 100%;
  text-align: center;
}

.cell-success {
  font-weight: bold;
  background-color: green;
  color: white;
  padding-top: 8px;
  width: 100%;
  height: 100%;
  text-align: center;
}

.cell-text-danger {
  font-weight: bold;
  color: darkred;
}

.cell-text-danger-dark {
  font-weight: bold;
  color: rgb(48, 1, 1);
}

.cell-text-success {
  color: green;
}

.row-header {
  font-weight: bold;
  text-decoration: underline;
}

.row-header-sortable {
  font-weight: bold;
  font-size: 14px;
  text-decoration: underline;
  cursor: default;
}

.row-warning {
  background-color: #fcf8e3;
}

.row-danger {
  background-color: #f2dede;
}

.list-row {
  margin-bottom: 2px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.list-row-odd {
  background-color: rgba(0,0,0,0.03);
}

/** DATE PICKER **/
.DayPickerInput {
  width: 100%;
}

.DayPickerInput input {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/** MODAL **/
.modal-x-large {
  width: 94%;
}

/** SPINNER **/
.glyphicon.normal-right-spinner {
  -webkit-animation: glyphicon-spin-r 2s infinite linear;
  animation: glyphicon-spin-r 2s infinite linear;
}

@-webkit-keyframes glyphicon-spin-r {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }

  100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
  }
}

@keyframes glyphicon-spin-r {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }

  100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
  }
}

.row-clickable {
  min-height: 50px;
  padding: 10px;
  margin-bottom: 5px;
  margin-top: 5px;
  cursor: pointer;
}

.event-over {
  background-color: rgb(183, 183, 183);
}

.event-now {
  background-color: rgb(177, 242, 184);
}

.event-attended {
  background-color: rgb(177, 242, 184);
}